const subscriptions = '/admin/subscriptions'
const orders = '/admin/orders'
const users = '/admin/users'

export const endpoints = {
  users,
  stats: '/admin/stats',
  getUser: `${users}/[id]`,
  getUserOrders: `${users}/[id]/orders`,
  getUserSubscriptions: `${users}/[id]/subscriptions`,
  storeUserSubscription: `${users}/[id]/subscriptions`,
  search: '/admin/search',
  usersSearch: `${users}/search`,
  export: `${subscriptions}/export`,
  subscriptionsConfig: '/admin/config/subscriptions',
  orders,
  getOrder: `${orders}/[id]`,
  getOrderFiles: `${orders}/[id]/files`,
  getOrderOptions: `${orders}/[id]/options`,
  latestOrders: `${orders}/latest`,
  fileDownload: '/admin/files/[id]/download',
  subscriptions,
  negativeUserSubscriptions: `${subscriptions}/negatives`,
  latestUserSubscriptions: `${subscriptions}/latest`,
  resetUserSubscriptions: `${subscriptions}/[id]/reset`,
  withdrawToUserSubscriptions: `${subscriptions}/[id]/copies/remove`,
  addToUserSubscriptions: `${subscriptions}/[id]/copies/add`,
  getUserSubscription: `${subscriptions}/[id]`,
  getSubscriptionInvoice: `${subscriptions}/[id]/invoice`,
  invoiceConfig: '/admin/configurations/invoice',
  mailConfig: '/admin/configurations/mail',
  workingHoursConfig: '/admin/configurations/working-hours',
  calendarEvents: '/admin/events',
  printOptionGroups: '/admin/print-options/groups',
  printOptionsByGroup: '/admin/print-options/groups/[group]',
  printOptions: '/admin/print-options/[id]',
  storePrintOptions: '/admin/print-options'

}

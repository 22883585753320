import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { endpoints } from './endpoints'
import { EntityId } from '~/types'

export * from './users'
export * from './orders'
export * from './subscriptions'
export * from './config'

export { endpoints } from './endpoints'

export const search = ({ search }, options) => request.get(endpoints.search + `?search=${search}`, options)
export const getStats = () => request.get(endpoints.stats)
export const exportSubscriptions = ({ date }: {date: string}) => request.download(endpoints.export + `?date=${date}`)
export const getDownloadLink = ({ id }: { id:EntityId}) => request.get<{url: string}>(endpoints.fileDownload.replace('[id]', id.toString()))

export const getEvents = data => request.get(endpoints.calendarEvents + `?start=${data.start}&end=${data.end}`)

const responseBody = <T>(response: AxiosResponse<T>) => camelcaseKeys(response.data, { deep: true }) as T

export const request = {
  get: <T>(url: string, options?: AxiosRequestConfig) => axios.get<T>(url, options).then(responseBody),
  download: <T>(url: string) => axios.get<T>(url, { responseType: 'blob' }).then((response: AxiosResponse<T>) => response.data),
  delete: <T>(url: string) => axios.delete<T>(`${url}`).then(responseBody),
  post: <T>(url: string, body: {}, options?: {}) =>
    axios.post<T>(url, snakecaseKeys(body), options).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(url, snakecaseKeys(body)).then(responseBody)
}
